
  import { Component, Vue } from 'vue-property-decorator'
  import getApi from '@/api'
  import QuizApi from '../api/quizApi'
  import { filter, includes, trim } from 'lodash'

  const quizApi: QuizApi = getApi('quiz')

  @Component({ components: {} })
  export default class CourseLog extends Vue {
    search = ''
    isLoading = false

    get filterData() {
      if (trim(this.search) === '') {
        return this.data
      }
      return filter(this.data, value => {
        return includes(value.attemptId, this.search)
      })
    }

    data: any[] = []
    async init() {
      try {
        this.isLoading = true
        this.data = await quizApi.getLogs()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    created() {
      this.init()
    }
  }
